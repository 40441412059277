import React, { useState } from 'react';

import Footer from '../../../components/footer';
import Navbar from '../../../components/navbar';
import ScrollToTop from '../../../components/scroll-to-top';
import Switcher from '../../../components/switcher';

import { useNavigate } from 'react-router-dom';
import useGetApi from '../../../api/useGetApi';

export default function ShopCategory() {
  const [query, setQuery] = useState({
    page: 1,
    limit: 100,
  });

  const { data, isLoading } = useGetApi({
    url: `/categories/all-category/admin-side`,
  });
  // const data = jsonData;
  const navigate = useNavigate();
  return (
    <>
      <Navbar navClass="defaultscroll is-sticky" />
      {/* disply ctegouries here */}
      <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 mt-14">
            <h3 className="text-3xl leading-normal font-semibold">
              Select Category
            </h3>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6   mt-4">
            {data?.results.map((category) => (
                <div
                    key={category._id}
                    className="bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition duration-300 ease-in-out cursor-pointer"
                    onClick={() =>
                        navigate(
                            `/shop-products/${category._id}?title=${category.title.en}`
                        )
                    }
                >
                    <img
                        className="mx-auto max-h-[150px] mb-2"
                        src={category?.imageUrl}
                        alt={category?.title?.en}
                    />
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                        {category.title.en}
                    </h3>
                    <p className="text-gray-600">
                        Click to explore products in this category.
                    </p>
                </div>
            ))}
          </div>
        </div>
      </section>
        <Footer/>
        <Switcher/>
        <ScrollToTop/>
    </>
  );
}
