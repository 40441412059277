import React, { useEffect, useState } from 'react';
import API from '../api/API';
import { useAppContext } from '../context/AppContext';
import { loginURL } from '../proxy';
import { customToast } from '../utils/customToast';
import { isAuthenticated } from './PrivateRoute';

const MessagePopup = ({ receiverId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);
  const store = useAppContext();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const updatedToken = localStorage.getItem('token_link_card');
    if (updatedToken) {
      setToken(updatedToken);
    }
  }, []);

  //   const

  // console.log(senderId);
  const handleSendMessage = async () => {
    if (!isAuthenticated()) {
      return (window.location.href = loginURL);
    }
    if (!token) return;
    setIsLoading(true);
    try {
      const response = await API.post(
        '/messages',
        {
          senderId: store?.state?.user?._id,
          receiverId,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        customToast(response?.data?.message);
        setMessage('');
        closeModal();
      }
    } catch (err) {
      customToast(err?.response?.data?.message || err?.message, 'error');
      // setError(err?.response?.data?.message || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={openModal}
        className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
      >
        Send Message
      </button>

      {isOpen && (
        <div
          className="fixed flex inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-2"
          onClick={closeModal}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-full max-w-xl shadow-lg rounded-md bg-white z-1 my-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Leave A Message
              </h3>
              <div className="mt-2 px-7 py-3">
                {/* <form onSubmit={(e) => e.stopPropagation()}> */}
                {/* <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2 text-left">Your Name:</label>
                    <input 
                      type="text" 
                      id="name" 
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                      placeholder="Name :"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2 text-left">Your Email:</label>
                    <input 
                      type="email" 
                      id="email" 
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                      placeholder="Email :"
                    />
                  </div> */}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-gray-700 text-sm font-bold mb-2 text-left"
                  >
                    Your Message:
                  </label>
                  <textarea
                    id="message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    rows="4"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Message :"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm"
                  onClick={handleSendMessage}
                >
                  {isLoading ? 'Sending...' : 'Send Message'}
                </button>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagePopup;
