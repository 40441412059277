import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import useGetApi from '../api/useGetApi';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const newToken = searchParams.get('token');
  const [state, setState] = useState({
    lang: 'en',
    addToCart: [],
    token: '',
    user: null,
  });
  const token = localStorage.getItem('token_link_card') || '';
  const cartData = localStorage.getItem('addToCart') || '';
  const [userId, setUserId] = useState('');

  const { data: userData } = useGetApi({
    url: userId && `users/profile/${userId}`,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      user: userData,
    }));
  }, [userData, newToken]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const lang = localStorage.getItem('lang') || 'en';
      const storedToken = localStorage.getItem('token_link_card') || '';
      let savedCart = [];

      if (storedToken) {
        try {
          savedCart = JSON.parse(localStorage.getItem('addToCart')) || [];
        } catch (error) {
          savedCart = [];
        }
      }

      const user = storedToken ? jwtDecode(storedToken) : {};
      console.log(user);

      setUserId(user?._id);
      setState((prev) => ({
        ...prev,
        addToCart: savedCart,
        lang,
        token: storedToken,
        user,
      }));
    }
  }, [newToken]);

  useEffect(() => {
    if (newToken) {
      localStorage.setItem('token_link_card', newToken);
      const user = jwtDecode(newToken);
      const savedCart = JSON.parse(localStorage.getItem('addToCart')) || [];

      setUserId(user?._id);
      setState((prev) => ({
        ...prev,
        token: newToken,
        user,
        addToCart: savedCart,
      }));
    }
  }, [newToken]);

  const onLanguageChange = (value) => {
    setState((prev) => ({
      ...prev,
      lang: value,
    }));
  };

  const onAddToCart = (item) => {
    if (!item) return;

    const data = {
      id: item?._id,
      title: item?.title,
      description: {
        en: item?.description?.en?.slice(0, 15),
        ar: item?.description?.ar?.slice(0, 15),
      },
      price: item?.price,
      image: item?.coverImgUrl,
      quantity: 1,
    };

    const alreadyAdded = state?.addToCart?.some((d) => d?.id === data?.id);

    if (alreadyAdded) {
      toast.error('Product already added to cart, please choose another one!');
      return;
    }

    const newCart = [...(state.addToCart || []), data];

    setState((prev) => ({
      ...prev,
      addToCart: newCart,
    }));
    toast.success('Item added to cart!');
    localStorage.setItem('addToCart', JSON.stringify(newCart));
  };

  const onRemoveCard = (id) => {
    const filterCard = state?.addToCart?.filter((card) => card?.id !== id);
    setState((prev) => ({
      ...prev,
      addToCart: filterCard,
    }));

    localStorage.setItem('addToCart', JSON.stringify(filterCard));
  };

  const onSetUser = (user = {}) => {
    setState((prev) => ({
      ...prev,
      user: user,
    }));
  };
  const onQuantityChange = (id, action) => {
    const updateQuantity = state?.addToCart?.map((product) =>
      product?.id === id
        ? {
            ...product,
            quantity:
              action === 'increase'
                ? product.quantity + 1
                : Math.max(product.quantity - 1, 1),
          }
        : product
    );

    setState((prevState) => ({
      ...prevState,
      addToCart: updateQuantity,
    }));

    localStorage.setItem('addToCart', JSON.stringify(updateQuantity));
  };
  // remve items from cart
  // const onRemoveCard = (id) => {
  //   const filterCard = state?.addToCart?.filter((card) => card?.id !== id);
  //   setState((prev) => ({
  //     ...prev,
  //     addToCart: filterCard,
  //   }));

  //   localStorage.setItem('addToCart', JSON.stringify(filterCard));
  // };
  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        onLanguageChange,
        onAddToCart,
        onRemoveCard,
        onQuantityChange,
        onSetUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
